<template>
	<div class="page">
		<div class="w1200 record_page">
			<!-- 音频 -->
			<audio controls ref="commonaudioCom" class="disnone">
				<source src="" />
			</audio>
			<div class="sysuserinfo">
				<img :src="userInfo.hlImage || require('@/assets/logo_img.png')" class="logoimg">
				<div class="usertitle fontsize20">
					{{ userInfo.nikename || '匿名' }}
				</div>
				<div class="btnbox">
					<div class="outlogin fontsize16" @click="outLogin">退出登录</div>
					<div class="btn2 fontsize16" v-if="!ifbangding" @click="handlebangding">绑定微信</div>
				</div>
			</div>
		</div>
		
		<div class="record_box_one">
			<div class="titilebox">
				<div class="titlename_box">
					<div class="itemobj fontsize16" @click="handlebookdtype(index)" :class="{'active':index==bookdtypeindex}" v-for="(item,index) in bookdtypeList" :key="index">
						{{item}}
					</div>
				</div>
			</div>
			<!-- 测试报告 -->
			<div class="echarts_box" v-show="bookdtypeindex==0">
				<div class="topbox">
					<div>
						<div class="text1">完成单词总数：{{tongjiObj.readingNum}}</div>
						<div class="text1">错词总数：{{tongjiObj.errNum}}</div>
					</div>
					<div class="datebox">
						<div class="left" @click="echartsDate('last')"><img class="icon" src="../assets/static/leftgo.png"></img></div>
						<div class="date1" v-if="categoryData.length>0">
							{{categoryData[0]}}
							<div class="line">-</div>
							{{categoryData[categoryData.length-1]}}
						</div>
						<div class="right" @click="echartsDate('next')"><img class="icon" src="../assets/static/leftgo.png"></img></div>
					</div>
				</div>
				<div class="echartsdiv" id="echartsdiv"></div>
				<div class="colorbox">
					<div class="item">
						<div class="text1" style="background-color: #055AF1;"></div>
						<div class="text2">每日学习数</div>
					</div>
					<div class="item">
						<div class="text1" style="background-color: #4CDE62;"></div>
						<div class="text2">每日消灭错词数</div>
					</div>
				</div>
			</div>
			<!-- 学连进度 -->
			<div class="recordconbox" v-if="bookdtypeindex==1">
				<div class="selebookbox">
					<div class="label fontsize20">选择词书:</div>
					<el-select size="medium" v-model="bookdIdxljd" placeholder="请选择,可输入搜索" filterable @change="getdatalistxljd">
						<el-option label="全部" :value="null"></el-option>
					    <el-option
					      v-for="(item,index) in myBooks"
					      :key="item.bookId"
					      :label="item.booksDTO.title"
					      :value="item.bookId">
					    </el-option>
					</el-select>
				</div>
				<div class="datalist">
					<div class="itemobj_lable">
						<div class="text1">词书</div>
						<div class="text2">单元</div>
						<div class="text16">单词速记</div>
						<div class="text16">词义连连</div>
						<div class="text16">听音拼写</div>
					</div>
					<div class="itemobj" v-for="(item,index) in dataListxljd" :key="index">
						<div class="text1">{{item.bookTitle}}</div>
						<div class="text2">{{item.lessonTitle}}</div>
						<div class="text16">
							<span v-if="item.dcsjStatus==0">未完成</span>
							<span class="zhuse" v-if="item.dcsjStatus==1">已完成</span>
						</div>
						<div class="text16">
							<span v-if="item.dcllStatus==0">未完成</span>
							<span class="zhuse" v-if="item.dcllStatus==1">已完成</span>
						</div>
						<div class="text16">
							<span v-if="item.typxStatus==0">未完成</span>
							<span class="zhuse" v-if="item.typxStatus==1">已完成</span>
						</div>
					</div>
				</div>
				<!-- 空 -->
				<div class="data_empty_box" style="padding-top: 68px;" v-if="dataListxljd.length<=0">
					<div class="icon"><img class="img100" :src="require('@/assets/static/kong.png')"></img></div>
					<div class="text">暂无数据</div>
				</div>
				<!-- 空 -->
				<!-- <div class="paginationbox">
					<div></div>
				    <el-pagination
				      @current-change="handleCurrentChangexljd"
				      :current-page.sync="formDataxljd.currentPage"
				      :page-size="formDataxljd.pageSize"
				      layout="prev, pager, next, jumper"
				      :total="totalxljd">
				    </el-pagination>
				</div> -->
				<div class="block30"></div>
			</div>
			<!-- 测试报告 -->
			<div class="recordconbox" v-if="bookdtypeindex==2">
				<div class="datalist">
					<div class="itemobj_lable">
						<div class="text1">词书</div>
						<div class="text2">项目</div>
						<div class="text3">内容</div>
						<div class="text4">分数</div>
						<div class="text5">时间</div>
					</div>
					<div class="itemobj" v-for="(item,index) in dataList" :key="index">
						<div class="text1">{{item.booksName}}</div>
						<div class="text2">{{item.typeName}}</div>
						<div class="text3">{{item.title}}</div>
						<div class="text4">{{item.score}}</div>
						<div class="text5">{{item.createDate}}</div>
					</div>
				</div>
				<!-- 空 -->
				<div class="data_empty_box" style="padding-top: 68px;" v-if="dataList.length<=0">
					<div class="icon"><img class="img100" :src="require('@/assets/static/kong.png')"></img></div>
					<div class="text">暂无数据</div>
				</div>
				<!-- 空 -->
				<div class="paginationbox">
					<div></div>
				    <el-pagination
				      @current-change="handleCurrentChange"
				      :current-page.sync="formData.currentPage"
				      :page-size="formData.pageSize"
				      layout="prev, pager, next, jumper"
				      :total="total">
				    </el-pagination>
				</div>
				<div class="block30"></div>
			</div>
		</div>
		

		<div class="block30"></div>
	</div>
</template>

<script>
	import {
		mapState,
		mapMutations
	} from "vuex";
	import {showLoading,hideLoading} from '@/common/loading.js';
	export default {
		data() {
			return {
				type:0,//1是听音读写， 2是从错词来的
				bookdtypeList:["单词统计","学练进度","测试报告"],
				bookdtypeindex:0,//下标
				dataList:[],
				total:0,
				formData: {
					currentPage: 1,
					pageSize: 9,
				},
				dataListxljd:[],
				totalxljd:0,
				bookdIdxljd:null,//选择词书
				formDataxljd: {
					currentPage: 1,
					pageSize: 9,
				},
				tongjiByDate:{
					readNum: 0,
					reviewNum: 0,
					studyTime: 0
				},//根据日期的统计数据
				tjwordobj:{
					endDate: "",
					startDate: "",
				},//单词量统计
				categoryData: [],//日期数据列表
				xuexiDate: [],
				fuxiData: [],
				studytimeData: [],//学习时间 分钟
				tjtimeobj:{
					endDate: "",
					startDate: "",
				},//学习时长统计
				baroption:{},//柱子表
				barmax:30,//y轴最大值
				timebarmax:20,//y轴最大值
				tongjiObj:{
					delErrNum: 0,//消除错词总数
					errNum: 0,//错词总数
					readingNum: 0,//学习总数
				},//统计数据
				ifbangding:false,//是否绑定
			};
		},
		mounted: function() {
			if(this.$route.query&&this.$route.query.type){
				this.bookdtypeindex = this.$route.query.type
			}
			//学习词书
			this.getMyReading()
		},
		computed: {
			...mapState(["userInfo", "isLogin", "myReadingObj",'openid','myBooks']),
		},
		methods: {
			...mapMutations(["SET_Data","SET_USERINFO",'SET_ISLOGIN','SET_vipTpyes']),
			navigato(name) {
				this.$router.push({
					name,
				});
			},
			//初始化
			initfunc(){
				if(this.myReadingObj){
					this.bookdIdxljd = this.myReadingObj.bookId
				}
				// 获取七天前的日期
				this.tjwordobj.startDate = this.$util.formatdate(this.subtractDays(7,new Date()))
				this.tjwordobj.endDate = this.$util.formatdate(new Date())
				if(this.bookdtypeindex==0){
					this.tongjiword()
				}else if(this.bookdtypeindex==1){
					//学练进度
					this.getdatalistxljd();
				}else if(this.bookdtypeindex==2){
					//获取测试报告
					this.getdatalist()
				}else{}
				//统计总数
				this.getsumCalendarList()
			},
			//学习词书
			getMyReading(){
				var _this = this
				var params = {
					userUuid:this.userInfo.uuid
				}
				_this.$http.post("app/word/userBooks/getMyReading", params).then(function(res) {		
					if (res.code === 200) {
						if(res.data){
							//_this.myReadingObj = res.data
							_this.SET_Data({ key: 'myReadingObj', value:res.data})
						}
						//我的词书
						_this.getMyBooks()
					} else {
						_this.$confirm(res.message, '提示', {
							confirmButtonText: '确定',
							cancelButtonText: '取消',
							type: 'warning'
						}).then(() => {}).catch(() => {});
					}
				})
			},
			//我的词书
			getMyBooks(){
				var _this = this
				var params = {
					userUuid:this.userInfo.uuid
				}
				_this.$http.post("app/word/userBooks/getMyBooks", params).then(function(res) {		
					if (res.code === 200) {
						if(res.data){
							_this.SET_Data({ key: 'myBooks', value:res.data})
						}
						//初始化
						_this.initfunc()
					} else {
						_this.$confirm(res.message, '提示', {
							confirmButtonText: '确定',
							cancelButtonText: '取消',
							type: 'warning'
						}).then(() => {}).catch(() => {});
					}
				})
			},
			//绑定微信
			handlebangding(){
				var _this = this
				if(!this.openid){
					_this.$confirm("绑定失败，openid不存在", '提示', {
						confirmButtonText: '确定',
						cancelButtonText: '取消',
						type: 'warning'
					}).then(() => {}).catch(() => {});
					return;
				}
				//查询内容
				var params = {
					openId:this.openid,
					wxName:this.userInfo.nikename
				}
				params["userId"] = this.userInfo.id
				this.$http.post('app/word/userBooks/saveOpenId', params).then(function(res) {
					//数据处理
					if (res.code == 200) {
						_this.ifbangding = true
						_this.$confirm("绑定成功！", '提示', {
							confirmButtonText: '确定',
							cancelButtonText: '取消',
							type: 'warning'
						}).then(() => {}).catch(() => {});
					} else {
						_this.$confirm(res.message, '提示', {
							confirmButtonText: '确定',
							cancelButtonText: '取消',
							type: 'warning'
						}).then(() => {}).catch(() => {});
					}
				})
			},
			//退出
			outLogin() {
				var _this = this;
				this.$confirm("确认要退出登录嘛？", "提示", {
						confirmButtonText: "确定",
						cancelButtonText: "取消",
						type: "warning",
					}).then(() => {
						showLoading()
						_this.$http.post("app/hlexam/login/logout", {}).then(function(res) {
							hideLoading()
							//数据处理
							if (res.code == 200) {
								_this.$message.success("退出登录成功！");
								sessionStorage.setItem("token", "");
								_this.SET_ISLOGIN(false);
								_this.SET_USERINFO({});
								//保存
								sessionStorage.setItem("key_state", "");
								location.href = "/login"
							} else {
								_this
									.$confirm(res.message, "提示", {
										confirmButtonText: "确定",
										cancelButtonText: "取消",
										type: "warning",
									})
									.then(() => {})
									.catch(() => {});
							}
						});
					})
					.catch(() => {});
			},
			//播放音频
			playAudioCom(src) {
				var _this = this
				try{
					_this.$refs.commonaudioCom.src = src;
					_this.$nextTick(res => {
					    _this.$refs.commonaudioCom.load()
					    _this.$refs.commonaudioCom.play()
					})
				}catch(e){
					//TODO handle the exception
				}
			},
			//统计总数
			getsumCalendarList(){
				var _this = this
				//查询内容
				var params = {}
				params["userUuid"] = this.userInfo.uuid
				this.$http.post('app/word/userBooks/sumCalendarList', params).then(function(res) {
					//数据处理
					if (res.code == 200) {
						if(res.data){
							_this.tongjiObj = res.data
						}
					} else {
						_this.$confirm(res.message, '提示', {
							confirmButtonText: '确定',
							cancelButtonText: '取消',
							type: 'warning'
						}).then(() => {}).catch(() => {});
					}
				})
			},
			// 从当前日期减去指定的天数
			subtractDays(days,date) {
				date.setDate(date.getDate() - days);
				return date;
			},
			addtheDays(days,date) {
				date.setDate(date.getDate() + days);
				return date;
			},
			//点击日期选择统计
			echartsDate(type){
				if(type=="last"){
					this.tjwordobj.endDate = this.tjwordobj.startDate
					this.tjwordobj.startDate = this.$util.formatdate(this.subtractDays(7,this.$util.getDateTime(this.tjwordobj.startDate)))
				}else{
					this.tjwordobj.startDate = this.tjwordobj.endDate
					this.tjwordobj.endDate = this.$util.formatdate(this.addtheDays(7,this.$util.getDateTime(this.tjwordobj.endDate)))
				}
				//统计单词
				this.tongjiword()
			},
			//学练进度
			getdatalistxljd(){
				var _this = this
				//查询内容
				var params = {
					bookId:this.bookdIdxljd
				}
				params["userUuid"] = this.userInfo.uuid
				showLoading()
				this.$http.post('app/word/userBooks/indexBooksLessonRecord', params).then(function(res) {
					hideLoading()
					//数据处理
					if (res.code == 200) {
						var records = res.data;
						var dataListxljd = []
						if(records&&records.length>0){
							records.forEach((item, index) => {
								item.lessonList.forEach((mitem, mindex) => {
									if(mitem.userBooksLesson){
										dataListxljd.push({
											bookTitle:item.booksDTO.title,
											lessonTitle:mitem.title,
											dcllStatus:mitem.userBooksLesson.dcllStatus,
											dcsjStatus:mitem.userBooksLesson.dcsjStatus,
											dycsStatus:mitem.userBooksLesson.dycsStatus,
											typxStatus:mitem.userBooksLesson.typxStatus,
										})
									}else{
										dataListxljd.push({
											bookTitle:item.booksDTO.title,
											lessonTitle:mitem.title,
											dcllStatus:0,
											dcsjStatus:0,
											dycsStatus:0,
											typxStatus:0,
										})
									}
								});
							});
							_this.totalxljd = res.data.total || 0;
							_this.dataListxljd = dataListxljd
						}
					} else {
						_this.$confirm(res.message, '提示', {
							confirmButtonText: '确定',
							cancelButtonText: '取消',
							type: 'warning'
						}).then(() => {}).catch(() => {});
					}
				})
			},
			//获取测试报告
			getdatalist(){
				var _this = this
				//查询内容
				var params = this.formData
				params["userUuid"] = this.userInfo.uuid
				showLoading()
				this.$http.post('app/word/userBooks/pageRecord', params).then(function(res) {
					hideLoading()
					//数据处理
					if (res.code == 200) {
						var records = res.data.records;
						//获取类型json
						// records.forEach((item, index) => {
						// 	try{
						// 		item["infoTags"] = JSON.parse(item.infoTags)
						// 	}catch(e){
						// 		item["infoTags"] = []
						// 	}
						// });
						_this.total = res.data.total;
						_this.dataList = records
					} else {
						_this.$confirm(res.message, '提示', {
							confirmButtonText: '确定',
							cancelButtonText: '取消',
							type: 'warning'
						}).then(() => {}).catch(() => {});
					}
				})
			},
			//统计
			tongjiword(){
				var _this = this
				//查询内容
				var params = this.tjwordobj
				params["userUuid"] = this.userInfo.uuid
				showLoading()
				this.$http.post('app/word/userBooks/getCalendarList', params).then(function(res) {
					hideLoading()
					//数据处理
					if (res.code == 200) {
						var list = res.data
						_this.categoryData = []
						_this.xuexiDate = []
						_this.fuxiData = []
						var barmax = _this.barmax
						var timebarmax = 0
						list.reverse().forEach((item, index) => {
							_this.categoryData.push(_this.$util.formatdatedian(item.createDate))
							_this.xuexiDate.push(item.readNum)
							_this.fuxiData.push(item.reviewNum)
							_this.studytimeData.push(item.studyTime)
							if(item.readNum>=item.reviewNum){
								if(item.readNum>barmax){
									barmax = item.readNum +20
								}
							}else{
								if(item.reviewNum>=barmax){
									barmax = item.reviewNum +20
								}
							}
							if(item.studyTime>timebarmax){
								timebarmax = item.studyTime
							}
						});
						_this.barmax = barmax
						_this.timebarmax = timebarmax + 10
						//渲染图标
						_this.$nextTick(() => {
						    _this.setecharts()
						});
					} else {
						_this.$confirm(res.message, '提示', {
							confirmButtonText: '确定',
							cancelButtonText: '取消',
							type: 'warning'
						}).then(() => {}).catch(() => {});
					}
				})
			},
			//图表生成
			setecharts() {
				var echarts = this.$echarts
				var chartDom = document.getElementById('echartsdiv');
				var myChart = echarts.init(chartDom);
				var option = {
					tooltip: {
					    trigger: 'axis',//axis
						axisPointer: {
						    type: 'cross',
						    crossStyle: {
						       color: '#999'
						    }
						}
					},
				    xAxis: {
				    	type: 'category',
				    	data: this.categoryData,
						axisLine:{
							lineStyle: {
							    color: '#222' // 改变为蓝色
							}
						},//去掉y轴 #FED8A7
						axisLabel: {
						    color: '#222',
						    interval: 0, // 横轴信息全部显示
						    //rotate: -30, // -30度角倾斜显示
						},
				    },
				    yAxis: {
				    	type: 'value',
						max:50,
				    	axisLine:{
							show:true,
							lineStyle: {
							    color: '#222' // 改变为蓝色
							}
						},//去掉y轴
				    	axisTick:{ 
				    		show:true,
				    	},
						splitLine: {
						    show: true,
						    lineStyle: {
						        // 设置分割线为虚线
						        type: 'dashed',
						        color: '#222' // 设置虚线的颜色
						    }
						}
				    },
				    grid: { x: 28, y: 18, x2: 3, y2: 20 },//调整此处大小即可控制空白 y是上下
				    series: [
				    	{
							name:"每日学习数",
				            data: this.xuexiDate, // 学习量
				            type: 'bar',
							itemStyle: {
							    color: '#055AF1' // 这里设置系列1的颜色为红色
							}
				        }, 
				    	{
							name:"每日消灭错词数",
				    		data: this.fuxiData, //复习
				    		type: 'bar',
							itemStyle: {
							    color: '#4CDE62' // 这里设置系列1的颜色为红色
							}
				    	}
				    ]     
				};
				//生成
				myChart.setOption(option);
			},
			//点击页数
			handleCurrentChange(currentPage) {
				this.formData.currentPage = currentPage;
				this.getdatalist();
			},
			handleCurrentChangexljd(currentPage) {
				this.formDataxljd.currentPage = currentPage;
				this.getdatalistxljd();
			},
			//下标
			handlebookdtype(type){
				this.bookdtypeindex = type
				if(type==0){
					if(this.categoryData.length<=0){
						//统计图表
						this.tongjiword()
					}
				}else if(type==1){
					if(this.dataListxljd.length<=0){
						//学练进度
						this.getdatalistxljd()
					}
				}else{
					if(this.dataList.length<=0){
						//获取测试报告
						this.getdatalist()
					}
				}
			},
			
			
		},
	};
</script>
<style lang="scss" scoped>
	.sysuserinfo {
		width: 100%;
		height: 128px;
		display: flex;
		align-items: center;
		.logoimg {
			width: 58px;
			height: 58px;
			border: 1px solid #FFF;
			border-radius: 50%;
			background-color: #f8f8f8;
		}
		.usertitle {
			flex: 1;
			margin-left: 8px;
			color: #FFFFFF;
			display: flex;
			align-items: center;
		}
		.btnbox{
			margin-left: 8px;
		}
		.outlogin{
			width: 88px;
			height: 32px;
			line-height: 32px;
			text-align: center;
			border-radius: 6px;
			border: 1px solid #E0E0E0;
			background: #ffffff;
			color: #666;
			cursor: pointer;
		}
		.btn2{
			margin-top: 12px;
			width: 88px;
			height: 32px;
			line-height: 32px;
			text-align: center;
			border-radius: 6px;
			border: 1px solid #E0E0E0;
			background: #ffffff;
			color: #666;
			cursor: pointer;
		}
	}
	/deep/ .el-pagination button{
		background-color: transparent !important;
		font-size:15px !important;
	}
	/deep/ .el-pager li{
		background: transparent !important;
		font-size:15px !important;
	}
	.record_page{
		width: 100%;
		overflow: hidden;
		position: relative;
		z-index: 2;
	}
	.record_box_one{
		width: 100%;
		padding: 0 10px;
		position: relative;
		overflow: hidden;
		z-index: 2;
		box-sizing: border-box;
		position: relative;
		border-radius: 20px 20px 0 0;
		border: 4px solid rgba(255, 255, 255, 0.00);
		background: linear-gradient(180deg, #FFF 16.74%, #F2F5FA 60.03%, #E1EAFB 99.98%);
		.titilebox{
			width: 100%;
			padding: 12px 0;
			display: flex;
			align-items: center;
			.titlename_box{
				width: 100%;
				display: flex;
				align-items: center;
				justify-content: space-between;
				.itemobj{
					width: 31%;
					height: 40px;
					line-height: 40px;
					text-align: center;
					border-radius: 6px;
					border: 1px solid #E0E0E0;
					background: #ffffff;
					color: #666;
					cursor: pointer;
				}
				.active{
					border: 1px solid #3773F5;
					background: #3773F5;
					color: #ffffff;
				}
			}
		}
		.recordconbox{
			width: 100%;
			// height: 628px;
			// overflow-y: auto;
			.selebookbox{
				width: 100%;
				display: flex;
				align-items: center;
				margin: 5px 0;
				.label{
					margin-right: 12px;
					color: #666;
				}
			}
			.datalist{
				width: 100%;
				.itemobj_lable{
					width: 100%;
					padding: 16px 0;
					display: flex;
					align-items: center;
					border-bottom: 1px solid #E0E0E0;
					color: #666;
					font-size: 13px;
					.text1{
						width: 20%;
						flex-shrink: 0;
					}
					.text2{
						width: 25%;
						flex-shrink: 0;
						text-align: center;
					}
					.text3{
						width: 15%;
						flex-shrink: 0;
						text-align: center;
					}
					.text4{
						width: 15%;
						flex-shrink: 0;
						text-align: right;
					}
					.text5{
						width: 23%;
						flex-shrink: 0;
						text-align: right;
					}
					.text16{
						width: 18%;
						flex-shrink: 0;
						text-align: center;
					}
				}
				.itemobj{
					width: 100%;
					padding: 16px 0;
					display: flex;
					align-items: center;
					border-bottom: 1px solid #E0E0E0;
					color: #222;
					font-size: 13px;
					.text1{
						width: 20%;
						flex-shrink: 0;
					}
					.text2{
						width: 25%;
						flex-shrink: 0;
						text-align: center;
					}
					.text3{
						width: 15%;
						flex-shrink: 0;
						text-align: center;
					}
					.text4{
						width: 15%;
						flex-shrink: 0;
						text-align: right;
					}
					.text5{
						width: 23%;
						margin-left: auto;
						flex-shrink: 0;
						text-align: right;
					}
					.text16{
						width: 18%;
						flex-shrink: 0;
						text-align: center;
					}
				}
				
			}
			.paginationbox{
				width: 100%;
				padding: 18px 0;
				box-sizing: border-box;
				display: flex;
				align-items: center;
				justify-content: space-between;
				margin-top: 20px;
				.btn1{
					width: 118px;
					margin: 0 10px;
					height: 40px;
					line-height: 40px;
					text-align: center;
					border-radius: 6px;
					border: 1px solid #3773F5;
					background: #3773F5;
					color: #ffffff;
					cursor: pointer;
				}
			}
		}
		.echarts_box{
			width: 100%;
			padding-bottom: 58px;
			.colorbox{
				width: 100%;
				margin-top: 18px;
				display: flex;
				align-items: center;
				justify-content: center;
				.item{
					display: flex;
					align-items: center;
					margin: 0 18px;
					.text1{
						width: 18px;
						height: 18px;
						border-radius: 38px;
					}
					.text2{
						font-style: 13px;
						margin-left: 10px;
					}
				}
			}
			.topbox{
				width: 100%;
				padding: 12px 0;
				display: flex;
				align-items: center;
				justify-content: space-between;
				.text1{
					color: #222;
					font-size: 13px;
				}
				.datebox{
					width: 158px;
					display: flex;
					align-items: center;
					justify-content: space-between;
					border:1px solid #298DFF;
					background-color: #298DFF;
					border-radius: 58px;
					padding: 6px 8px;
					color: #ffffff;
					.date1{
						font-size: 13px;
						display: flex;
						align-items: center;
						justify-content: center;
						.line{
							padding: 0 5px;
						}
					}
					.left{
						width: 28px;
						display: flex;
						align-items: center;
						cursor: pointer;
						.icon{
							width: 15px;
							height: 15px;
						}
					}
					.right{
						width: 28px;
						display: flex;
						align-items: center;
						justify-content: flex-end;
						cursor: pointer;
						.icon{
							width: 15px;
							height: 15px;
							transform: rotate(180deg);
						}
					}
				}
			}
			.echartsdiv{
				width: 100%;
				height: 388px;
			}
		}
	}
</style>